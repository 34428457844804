<template>
  <div data-app>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12" style="color: #e33354" class="ml-4">
            <h3>{{ cardTitle }}</h3>
          </v-col>
          <v-col cols="12" sm="6" md="6" style="color: #e33354" class="mt-4">
            <date-range-picker
              v-model="dateRange"
              :date-format="dateFormat"
              :locale-data="localeData"
              opens="right"
              :ranges="false"
              @update="exportOrder"
            ></date-range-picker>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="3" md="3">
            <v-select
              v-if="statuses.webshop && statuses.webshop.order"
              v-model="status"
              :items="statuses.webshop.order"
              label="Status"
              item-text="$t('ORDER_STATUSES.'+value)"
              item-value="key"
            >
              <template slot="selection" slot-scope="slotProps">
                {{ $t("ORDER_STATUSES." + slotProps.item.key) }}
              </template>
              <template v-slot:item="slotProps">
                <span class="navi-text">{{
                  $t("ORDER_STATUSES." + slotProps.item.key)
                }}</span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-text-field
              v-model="search"
              append-icon="search"
              :label="$t('FORMS.search')"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="filteredOrderCollection"
          :search="search"
          :sort-by="['id']"
          sort-desc="true"
          :loading="loadingTable"
          @click:row="handleClickItem"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <OrderModalForm
                :modalData="modalData"
                :permissions="permissions"
                :statuses="statuses"
                :endPoint="endPoint"
                :orderCollection="orderCollection"
                @closeModalForm="handleCloseModalForm"
                @saveModalForm="handleSaveModalForm"
                @new="handleNew"
              ></OrderModalForm>
              <v-spacer></v-spacer>
              <DeleteModalDialog
                :dialogDelete="dialogDelete"
                @closeDelete="handleCloseDelete"
                @deleteItemConfirm="handleDeleteItemConfirm"
              >
              </DeleteModalDialog>
            </v-toolbar>
          </template>
          <template v-slot:item.name="{ item }">
            <span v-if="item.custom_fields">
              {{ item.custom_fields.surname }}
              {{ item.custom_fields.christian_name1 }}
            </span>
          </template>
          <template v-slot:item.date="{ item }">
            <span v-if="item.custom_fields">
              {{ item.custom_fields.ordered_at }}
            </span>
          </template>
          <template v-slot:item.phone="{ item }">
            <span v-if="item.custom_fields">
              {{ item.custom_fields.phone }}
            </span>
          </template>
          <template v-slot:item.email="{ item }">
            <span v-if="item.custom_fields">
              {{ item.custom_fields.email }}
            </span>
          </template>
          <template v-slot:item.amount="{ item }">
            <span v-if="item.custom_fields">
              {{ item.custom_fields.amount }} {{ item.custom_fields.currency }}
            </span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              color="primary"
              v-if="permissionCan('delete')"
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";

import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import ApiService from "@/core/services/api.service";

import { mapGetters, mapActions } from "vuex";

import SiteService from "@/core/services/site.service.js";

import { listModelsMixins } from "@/view/mixins/listModelsMixins.js";

import OrderModalForm, { initialFormData } from "./OrderModalForm";

import DeleteModalDialog from "@/view/components/DeleteModalDialog";

export const PERMISSION_TO = "products.category.";

export default {
  name: "Orders",
  components: { OrderModalForm, DeleteModalDialog, DateRangePicker },
  mixins: [listModelsMixins],
  data() {
    return {
      cardTitle: this.$t("WEBSHOP.orders"),
      search: "",
      routePath: "/webShop/orders/",
      permissionTo: PERMISSION_TO,
      permissions: JSON.parse(localStorage.permissions),
      headers: [
        {
          text: "ID",
          value: "order_number",
          // width: "100px",
        },
        {
          text: this.$t("FORM_INPUT_NAMES.date"),
          value: "date",
        },
        {
          text: this.$t("TABLE.name"),
          value: "name",
        },
        {
          text: this.$t("TABLE.phone"),
          value: "phone",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: this.$t("TABLE.amount"),
          value: "amount",
        },
        // {
        //   text: this.$t("TABLE.company"),
        //   value: "phone",
        // },
        // { text: "Status", value: "status" },
        {
          text: this.$t("TABLE.actions"),
          value: "actions",
          sortable: false,
          width: "100px",
        },
      ],

      modalData: {
        dialog: false,
        editedId: null,
      },
      editedItem: Object.assign({}, initialFormData()),

      dialogDelete: false,
      dateRange: {
        startDate: new Date(),
        endDate: new Date(),
      },

      localeData: {
        direction: "ltr",
        format: "yyyy-mm-dd",
        separator: " - ",
        applyLabel: "Export CSV",
        cancelLabel: "Mégse",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: ["Vas", "Hét", "Ked", "Sze", "Csü", "Pén", "Szo"],
        monthNames: [
          "Jan",
          "Feb",
          "Már",
          "Ápr",
          "Máj",
          "Jun",
          "Jul",
          "Aug",
          "Sze",
          "Okt",
          "Nov",
          "Dec",
        ],
        firstDay: 1,
      },
      status: 3,
      // filteredOrderCollection: [],
    };
  },

  computed: {
    ...mapGetters(["orderCollection", "statuses", "productCollection"]),

    endPoint() {
      // return "user/";
      return "webshop/admin/" + SiteService.getActiveSiteId() + "/order/";
      // api.loc/api/webshop/admin/1/order/
    },
    filteredOrderCollection() {
      let status = !this.status ? null : this.status;
      return this.orderCollection.filter((item) => item.status == status);
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.handleCloseDelete();
    },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.editItem(id);
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(["fetchOrder", "fetchProduct"]),

    fetchModel() {
      this.fetchProduct();
      return this.fetchOrder();
    },

    dateFormat(classes, date) {
      if (!classes.disabled) {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        classes.disabled = date.getTime() > tomorrow;
      }
      return classes;
    },

    getPackageQuantity(products) {
      let packages = {};
      products.map((item) => {
        item.product = {
          ...this.$store.getters.getProductByID(item.product_id),
        };

        if (!packages[item.product["shipping_quantity"]]) {
          packages[item.product["shipping_quantity"]] = 0;
        }
        packages[item.product["shipping_quantity"]] += +item.quantity;
        return item;
      });

      let quantity = 0;

      Object.entries(packages).forEach((item) => {
        quantity += item[1] / item[0];
      });
      // console.log("!!!!!!!!!!!!!!!!!", Object.entries(packages), quantity);

      return quantity;
    },

    formatOrderToCSV(order) {
      // console.log(order.custom_fields);
      let custom_fields = order.custom_fields;
      let formatted = [];
      if (!custom_fields || custom_fields.shipping_type != "delivery") {
        return null;
      }

      if (custom_fields.payment_type == "cash") {
        formatted.push(custom_fields.amount || "");
      } else {
        formatted.push("0");
      }

      formatted.push(
        custom_fields.surname + " " + custom_fields.christian_name1
      );
      formatted.push(custom_fields.shipping_address.address || "");
      formatted.push(custom_fields.shipping_address.city || "");
      formatted.push(custom_fields.shipping_address.zip || "");
      formatted.push(custom_fields.email || "");
      formatted.push(custom_fields.phone || "");
      formatted.push("");
      formatted.push("");
      formatted.push(custom_fields.message || "");
      // formatted.push("1");
      formatted.push(this.getPackageQuantity(custom_fields.products));
      formatted.push("HU");
      formatted.push("");
      formatted.push("");
      formatted.push("");

      return formatted;
    },

    exportOrdersToCsv(filename, orders) {
      console.log(orders);
      let rows = [];
      let header = [
        "Utánvét",
        "Név",
        "Cím",
        "Város",
        "irányitószám",
        "email",
        "telefon",
        "utánvét hívatkozás",
        "ügyfél hívatkozás",
        "Megjegyzés",
        "darabszám",
        "ország",
        "kapcsolattartó",
        "szolgáltatás",
        "házszám",
        "épület/lépcsőház",
      ];

      rows = [header, ...rows];
      let formatted = null;
      orders.forEach((element) => {
        formatted = this.formatOrderToCSV(element[1]);
        if (formatted) {
          rows = [...rows, formatted];
        }
      });

      var processRow = function (row) {
        var finalVal = "";
        for (var j = 0; j < row.length; j++) {
          var innerValue = row[j] === null ? "" : row[j].toString();
          if (row[j] instanceof Date) {
            innerValue = row[j].toLocaleString();
          }
          var result = innerValue.replace(/"/g, '""');
          if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
          if (j > 0) finalVal += ";";
          finalVal += result;
        }
        return finalVal + "\n";
      };

      var csvFile = "";
      for (var i = 0; i < rows.length; i++) {
        csvFile += processRow(rows[i]);
      }

      var blob = new Blob([csvFile], { type: "text/csv;charset=win1250;" });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", filename);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },

    exportOrder() {
      ApiService.post("1/order/getAllOrders", this.dateRange)
        .then(({ data }) => {
          // console.log(data);

          let filename =
            "Rendelesek_" +
            this.dateRange.startDate
              .toLocaleDateString("hu-HU")
              .replaceAll(". ", "-")
              .replaceAll(".", "_") +
            this.dateRange.endDate
              .toLocaleDateString("hu-HU")
              .replaceAll(". ", "-") +
            "csv";

          this.exportOrdersToCsv(filename, Object.entries(data["orders"]));
        })
        .catch((error) => {
          console.log("Error!: ", error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
  },

  mounted() {
    this.loadingTable = true;
    this.fetchModel().then(() => {
      this.loadingTable = false;
    });
  },
};
</script>
